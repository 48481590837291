<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Laboratory Safety Agreement</h2>

      <p class="mb-n3">
        I have read carefully the article titled "Safety in the Chemistry Laboratory".
      </p>

      <v-radio-group v-model="inputs.input1" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        I have read carefully the article titled “First Aid in the Chemistry Laboratory”.
      </p>

      <v-radio-group v-model="inputs.input2" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        I have made myself familiar with the location of the eyewashes, safety showers, fire
        extinguishers and the first aid kit.
      </p>

      <v-radio-group v-model="inputs.input3" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p style="font-weight: bold" class="mb-2">I agree to wear:</p>

      <ul class="mb-3">
        <li>my safety glasses/goggles</li>
        <li>lab coat</li>
        <li>clothing that covers my legs</li>
        <li>shoes that completely cover my feet</li>
      </ul>

      <p class="mb-n3" style="font-weight: bold">
        at all times during the laboratory session unless given express permission by the instructor
        to remove them.
      </p>

      <v-radio-group v-model="inputs.input4" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        I have read and understood the articles titled “Safety in the Chemistry Laboratory” and
        “First Aid in the Chemistry Laboratory” and I agree to abide by the rules and procedures
        described in the articles and this agreement. I will also abide by any other rules and
        regulations provided by my chemistry instructor.
      </p>

      <v-radio-group v-model="inputs.input5" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3" style="font-weight: bold">
        I understand the conditions of the policy on late submission of reports and I agree to abide
        by those conditions.
      </p>

      <v-radio-group v-model="inputs.input6" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0 pt-2">
        Please initial in the space below. These initials will serve as a replacement for a date and
        signature to indicate that you will fully comply with the statements you agreed to above.
      </p>

      <p>
        Initials:
        <v-text-field
          v-model="inputs.initials"
          style="max-width: 100px"
          class="d-inline-flex centered-input"
        />
        Date:
        <input v-model="inputs.signingDate" type="date" min="2024-09-01" max="2030-12-31" />
        &nbsp;
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'uOttawaSafetyWaiver',
  components: {ChemicalLatex, StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        initials: null,
        signingDate: null,
      },
      options: [{text: 'I agree', value: 'agree'}],
    };
  },
  computed: {
    today() {
      return new Date().toJSON().slice(0, 10);
    },
  },
};
</script>
